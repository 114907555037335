/* eslint-disable no-unneeded-ternary */
import { MantineProvider, MantineThemeOverride } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { MedplumClient } from '@medplum/core';
import { MedplumProvider } from '@medplum/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { App } from './App';
import { getConfig } from './config';
import { AppProvider } from './AppProvider';
import { getPractitionerRole, getStripeRenewSession } from './utils/util';

if ('serviceWorker' in navigator) {
  // Clear all server workers
  // Once upon a time, we used a service worker to cache static assets.
  // We don't do that anymore, but the old service worker is still there.
  // This code removes it.
  // Someday we can remove this code.
  window.addEventListener('load', () => {
    navigator.serviceWorker
      .getRegistrations()
      .then((regs) => Promise.all(regs.map((r) => r.unregister())))
      .catch((regError) => console.error('SW registration failed: ', regError));
  });
}

export async function initApp(): Promise<void> {
  const config = getConfig();

  const medplum = new MedplumClient({
    baseUrl: config.baseUrl,
    clientId: config.clientId,
    cacheTime: 60000,
    autoBatchTime: 100,
    onUnauthenticated: () => {
      if (window.location.pathname !== '/signin' && window.location.pathname !== '/oauth') {
        window.location.href = '/signin?next=' + encodeURIComponent(window.location.pathname + window.location.search);
      }
    },
  });

  const activeLogin = medplum?.getActiveLogin();
  const isLoggedIn = activeLogin ? true : false;

  // if (
  //   !isLoggedIn &&
  //   window.location.pathname !== '/signin' &&
  //   window.location.pathname !== '/oauth' &&
  //   window.location.pathname !== '/register'
  // ) {
  //   window.location.href = '/signin';
  // }

  //added code for redirect to stripe payment page
  //when user click on payment link from reminder email
  const resourceID = medplum.getActiveLogin()?.profile?.reference;
  const parts: any = resourceID?.split('/');
  const practitionerId = parts?.[1];
  const startStripeSession = async () => {
    await getPractitionerRole(medplum, practitionerId)
      .then(async (response: any) => {
        const role = response?.entry?.[0]?.resource?.code?.[0]?.coding?.[0]?.display || '';
        if (!role) return;
        const payload = {
          role: role,
        }
        getStripeRenewSession(medplum, payload).then((res) => {
          window.location.href = res.sessionUrl;
        }).catch((err) => {
          console.log(err);
        });
      });
  };

  if (isLoggedIn && window.location.pathname.includes('pay-to-renew')) {
    startStripeSession();
  } else {
    if (!isLoggedIn && window.location.pathname !== "/" && window.location.pathname !== '/signin' && !window.location.pathname.includes('setpassword') && window.location.pathname !== '/oauth' && window.location.pathname !== '/register' && window.location.pathname !== '/privacy' && window.location.pathname !== '/terms-conditions' && window.location.pathname !== '/activate-account' && window.location.pathname !== '/sign-up' && window.location.pathname !== '/pricing' && window.location.pathname !== '/non-npi-user-form') {
      if (window.location.pathname !== '/signin') {
        sessionStorage.setItem('returnTo', window.location.pathname);
        window.location.href = '/signin';
      }
    }

    if (isLoggedIn && (window.location.pathname === '/signin' || window.location.pathname === '/home')) {
      window.location.href = '/session';
    }
  }


  const theme: MantineThemeOverride = {
    headings: {
      sizes: {
        h1: {
          fontSize: '1.125rem',
          fontWeight: 500,
          lineHeight: 2.0,
        },
      },
    },
    fontSizes: {
      xs: '0.6875rem',
      sm: '0.875rem',
      md: '0.875rem',
      lg: '1.0rem',
      xl: '1.125rem',
    },
  };

  const router = createBrowserRouter([{ path: '*', element: <App /> }]);

  const navigate = (path: string): Promise<void> => router.navigate(path);

  const root = createRoot(document.getElementById('root') as HTMLElement);
  root.render(
    <>
      <MedplumProvider medplum={medplum} navigate={navigate}>
        <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
          <AppProvider>
            <Notifications position="bottom-right" />
            <RouterProvider router={router} />
          </AppProvider>
        </MantineProvider>
      </MedplumProvider>
    </>
  );
}

if (process.env.NODE_ENV !== 'test') {
  initApp().catch(console.error);
}
