/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Card, Grid, createStyles, Text, Divider } from '@mantine/core';
import React, { useEffect, useState } from 'react';
import { getTemplateListAccordingToRole, getPractitionerRole } from '../utils/util';
import { useMedplum } from '@medplum/react';
import { toast } from 'react-toastify';

const useStyles = createStyles((theme) => ({
  section: {
    padding: theme.spacing.md,
  },
}));
export interface TemplateNoteProps {
  selectedTemplateNote: (selectedItem: any) => void;
  summary: any;
}
const TemplateList: React.FC<TemplateNoteProps> = (props: TemplateNoteProps) => {
  const { classes } = useStyles();
  const medplum = useMedplum();
  const resourceID = medplum.getActiveLogin()?.profile?.reference;
  const parts: any = resourceID?.split('/');
  const practitionerId = parts[1];
  const [disabledButtons, setDisabledButtons] = useState<string[]>([]);
  const [visitTypeDisabledButtons, setVisitTypeDisabledButtons] = useState<string | null>(null);
  const [visitType, setVisitType] = useState<any[]>([]);
  const [additionalNotes, setAdditionalNotes] = useState<any[]>([]);
  //const [customNotes, setCustomNotes] = useState<any[]>([]);

  useEffect(() => {
    getPractitionerRole(medplum, practitionerId)
      .then((response: any) => {
        const specialtyCode = response?.entry?.[0]?.resource?.code?.[0]?.coding?.[0]?.display || '';
        getTemplateListAccordingToRole(medplum, specialtyCode)
          .then((response: any) => {
            const visitTypeEntries = response.entry?.filter((entry: any) => entry.resource.type.coding[0].code === 'visit-type');
            const visitType = visitTypeEntries?.map((item: { resource: { title: string } }) => item.resource)
            setVisitType(visitType)

            const additionaNotesEntries = response.entry?.filter((entry: any) => entry.resource.type.coding[0].code === 'additional-note');
            const additionalNotes = additionaNotesEntries?.map((item: { resource: { title: string } }) => item.resource);
            setAdditionalNotes(additionalNotes);

            // const customNotesEntries = response.entry?.filter((entry: any) => entry.resource.type.coding[0].code === 'custom-note');
            // const customNotesOrder = customNotesEntries?.map((item: { resource: { title: string } }) => item.resource);
            // setCustomNotes(customNotesOrder);

            if (props?.summary) {
              const summaryCompositionIds = props?.summary.map((entry: { compositionId: any; }) => entry.compositionId);
              setDisabledButtons(summaryCompositionIds);
              const matchingVisits = visitType.filter((visit: any) => {
                return props?.summary.some((entry: any) => entry.compositionId === visit.id);
              });

              // Set matching visits in visitTypeDisabledButtons
              setVisitTypeDisabledButtons(matchingVisits[0]?.id);
            }
          })
          .catch((error: any) => {
            console.error('Error fetching data:', error);
            toast.error('Failed to fetch session details.');
          });
      })
      .catch((error: any) => {
        console.error('Error fetching practitioner role:', error);
        toast.error('Failed to fetch practitioner role.');
      });
  }, []);

  const handleClick = (data: any, event: string) => {
    if (event === 'visitType') {
      if (visitTypeDisabledButtons === data.id) {
        setVisitTypeDisabledButtons(null);
      } else {
        setVisitTypeDisabledButtons(data.id);
      }
    } else {
      setDisabledButtons(prevDisabledButtons => [...prevDisabledButtons, data.id]);
    }
    props.selectedTemplateNote(data)
  };
  return (
    <>
      <Card >
        <Card.Section className={classes.section} p="lg" style={{ borderBottom: '0px !important' }}>
          <Grid columns={12}>
            <Grid.Col span={12} md={12} lg={12} className='tamplates'>
              <div className="tabContainer">
                <Text style={{ fontWeight: '500' }} ml={9} mb={6} size="lg" color="#228be6">Visit Type</Text>
                {visitType?.map((data: any) => (
                  <button className="template-option"
                    key={data.id}
                    onClick={() => handleClick(data, 'visitType')}
                    disabled={visitTypeDisabledButtons === data.id}
                    style={{ cursor: `${visitTypeDisabledButtons === data.id ? 'not-allowed' : 'pointer'} }` }}
                  >
                    {data?.title}
                  </button>
                ))}
              </div>
              <Divider size="md" className="dividerStyles" />
              <div className="tabContainer" >
                <Text style={{ fontWeight: '500' }} ml={9} mb={15} mt={10} size="lg" color="#228be6">Generate Additional Notes</Text>
                <div>
                  {additionalNotes?.map((data: any) => (
                    <button className="template-option"
                      key={data.id}
                      onClick={() => handleClick(data, 'additionalNotes')}
                      disabled={disabledButtons.includes(data.id)}
                      style={{ cursor: `${disabledButtons.includes(data.id) ? 'not-allowed' : 'pointer'} }` }}
                    >
                      {data?.title}
                      {disabledButtons.includes(data.id)}
                    </button>
                  ))}
                </div>
              </div>
              {/* <Divider size="md" className="dividerStyles" />
              <div className="tabContainer" >
                <Text style={{ fontWeight: '500' }} ml={9} mb={15} mt={10} size="lg" color="#228be6">Custom Notes</Text>
                <div>
                  {customNotes?.map((data: any) => (
                    <button className="template-option"
                      key={data.id}
                      onClick={() => handleClick(data, 'customNotes')}
                      disabled={disabledButtons.includes(data.id)}
                      style={{ cursor: `${disabledButtons.includes(data.id) ? 'not-allowed' : 'pointer'} }` }}
                    >
                      {data?.title}
                      {disabledButtons.includes(data.id)}
                    </button>
                  ))}
                </div>
              </div> */}
            </Grid.Col>
          </Grid>
        </Card.Section>
      </Card>
    </>
  );
};

export default TemplateList;